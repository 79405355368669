@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('./fonts/SourceSansPro-Regular.ttf');
}

.App {
  text-align: center;
  font-family: SourceSansPro-Regular;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrong-mappings {
  position: absolute;
  width: 560px;
  height: 460px;
  top: calc(50% - 200px);
  left: calc(50% - 280px);
  z-index: 9;
  background-color: #1f2323;
  box-shadow: 0 0 5px 2px #1a1919;
}

.wrong-mappings .header {
  background-color: #272828;
  margin: 0;
  padding: 8px 12px;
  text-align: left;
  font-size: 16px;
  font-weight: 800;
  display: flex;
  color: #c3c3c3;
}

.wrong-mappings-list {
  overflow: scroll;
  height: 365px;
  padding: 24px;
  color: #999;
}

.wrong-mappings-list div {
  margin-top: 0;
  padding: 0;
  background-color: #333;
}

.missingUicRefPopup {
  position: absolute;
  width: 560px;
  height: 460px;
  top: calc(50% - 200px);
  left: calc(50% - 280px);
  z-index: 9;
  background-color: #1f2323;
  box-shadow: 0 0 5px 2px #1a1919;
}

.missingUicRefPopup a {
  color: #999;
}

.missingUicRefPopup .header {
  background-color: #272828;
  margin: 0;
  padding: 8px 12px;
  text-align: left;
  font-size: 16px;
  font-weight: 800;
  display: flex;
  color: #c3c3c3;
}

.public-transport-moreRoutes {
  position: absolute;
  width: 560px;
  height: 460px;
  top: calc(50% - 200px);
  left: calc(50% - 280px);
  z-index: 8;
  background-color: #1f2323;
  box-shadow: 0 0 5px 2px #1a1919;
}

.public-transport-moreRoutes .header {
  background-color: #272828;
  margin: 0;
  padding: 8px 12px;
  text-align: left;
  font-size: 16px;
  font-weight: 800;
  display: flex;
  color: #c3c3c3;
}

.public-transport-moreRoutes .button {
  background-color: #2456b6;
  font-weight: 300;
  font-size: 12px;
  text-transform: none;
  position: relative;
  top: 12px;
  width: 125px;
  height: 25px;
  border-radius: 2px;
  margin-left: 12px;
  color: #f1f1f1;
}

.public-transport-moreRoutes .button:hover {
  background-color: #2d6de2;
}

.public-transport-moreRoutes .button:disabled {
  background-color: #3c4444;
  color: #9ea3a3;
}


.public-transport-moreRoutes-table {
  font-size: 12px;
  margin: 12px;
}

.public-transport-moreRoutes-table .city-header {
  background-color: #1a1919;
  padding-bottom: 8px;
}

.public-transport-moreRoutes-table div {
  margin-top: 8px;
  text-align: left;
}

.public-transport-moreRoutes-table div div {
  width: 120px;
  font-size: 12px;
  color: #999;
  padding: 2px 8px;
}

.public-transport-builder {
  position: absolute;
  width: 650px;
  height: 460px;
  top: calc(50% - 200px);
  left: calc(50% - 335px);
  z-index: 7;
  background-color: #1f2323;
  box-shadow: 0 0 5px 2px #1a1919;
}

.public-transport-builder .header {
  background-color: #272828;
  margin: 0;
  padding: 8px 12px;
  text-align: left;
  font-size: 16px;
  font-weight: 800;
  display: flex;
  color: #c3c3c3;
}

.public-transport-builder .button {
  background-color: #2456b6;
  font-weight: 300;
  font-size: 12px;
  text-transform: none;
  position: relative;
  top: 12px;
  width: 125px;
  height: 25px;
  border-radius: 2px;
  margin-left: 12px;
  color: #f1f1f1;
}

.public-transport-builder .button:hover {
  background-color: #2d6de2;
}

.public-transport-builder-routes {
  font-size: 12px;
  display: flex;
  margin: 0;
  color: #999;
  position: relative;
  background-color: #333;
}

.dot-flashing {
  top: 4px;
  margin-left: 16px;
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #5bbd5f;
  color: #5bbd5f;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -9px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #5bbd5f;
  color: #5bbd5f;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 9px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #5bbd5f;
  color: #5bbd5f;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #5bbd5f;
  }

  50%,
  100% {
    background-color: #0f0f0f;
  }
}

.public-transport-actions {
  display: flex;
  position: absolute;
  bottom: 24px;
  left: calc(50% - 200px);
  background-color: #272828;
  z-index: 7;
  width: 400px;
  height: 50px;
  padding: 4px 8px;
  border-radius: 2px;
}

.public-transport-actions button {
  background-color: #2456b6;
  font-weight: 300;
  font-size: 12px;
  text-transform: none;
  position: relative;
  top: 12px;
  width: 95px;
  height: 25px;
  border-radius: 2px;
  margin-left: 12px;
  color: #f1f1f1;
}

.public-transport-actions button.close {
  background-color: #cf4533;
}

.public-transport-actions button.close:hover {
  background-color: #e44c38;
}

.public-transport-actions button:hover {
  background-color: #2d6de2;
}

.public-transport-actions-tooltip {
  position: absolute;
  bottom: 100px;
  border-radius: 2px;
  padding: 4px 8px;
  left: calc(50% - 165px);
  background-color: #272828;
  z-index: 7;
  width: 330px;
  height: 100px;
  padding: 4px 8px;
  border-radius: 2px;
  font-weight: 300;
  font-size: 12px;
  color: #999;
}

.public-transport-actions-tooltip div {
  position: relative;
  top: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.public-transport-actions-tooltip button {
  background-color: #5bbd5f;
  width: 60px;
  font-size: 12px;
  font-weight: 300;
  text-transform: none;
  height: 30px;
  border-radius: 2px;
}

.public-transport-actions-tooltip button:hover {
  background-color: #68d86c;
}

.select-station {
  position: absolute;
  bottom: 80px;
  border-radius: 2px;
  padding: 4px 8px;
  left: calc(50% - 165px);
  background-color: #272828;
  z-index: 7;
  width: 330px;
  height: 480px;
  padding: 4px 8px;
  border-radius: 2px;
  align-items: center;
  /* display: flex; */
  font-weight: 300;
  font-size: 12px;
  color: #999;
}

.select-station button {
  width: 330px;
  background-color: #5bbd5f;
  border-radius: 2px;
  text-transform: none;
}

.select-station button:hover {
  background-color: #68d86c;
}

.select-station-selection {
  background-color: #1f2323;
  padding-left: 8px;
  margin-top: 24px;
}

.select-station h5 {
  margin: 0;
  padding: 8px 16px;
  font-size: 16px;
}

.public-transport-actions-codesnippet {
  position: absolute;
  bottom: 220px;
  border-radius: 2px;
  padding: 4px 8px;
  left: calc(50% - 165px);
  background-color: #272828;
  z-index: 7;
  width: 330px;
  height: 400px;
  padding: 4px 8px;
  border-radius: 2px;
  align-items: center;
  /* display: flex; */
  font-weight: 300;
  font-size: 12px;
  color: #999;
}

.public-transport-actions-codesnippet textarea {
  width: 300px;
  height: 255px;
  background-color: #272828;
  color: #999;
  overflow: scroll;
  outline: none;
}

.public-transport-actions-codesnippet button {
  background-color: #2456b6;
  font-weight: 300;
  font-size: 12px;
  text-transform: none;
  color: #f1f1f1;
  border-radius: 2px;
  width: 95px;
  height: 25px;
}

.public-transport-actions-codesnippet button:hover {
  background-color: #2d6de2;
}

.try-routing-popup {
  display: flex;
  position: absolute;
  top: 40px;
  border-radius: 2px;
  padding: 4px 8px;
  left: calc(50% - 265px);
  background-color: #272828;
  z-index: 7;
  width: 530px;
  height: 40px;
  padding: 4px 8px;
  border-radius: 2px;
  align-items: center;
  /* display: flex; */
  font-weight: 300;
  font-size: 12px;
  color: #999;
}

.try-routing-popup button {
  background-color: #2456b6;
  font-weight: 300;
  font-size: 12px;
  text-transform: none;
  color: #f1f1f1;
  border-radius: 2px;
  width: 95px;
  height: 25px;
  margin-right: 24px;
}

.try-routing-popup button:hover {
  background-color: #2d6de2;
}

.try-routing-popup input {
  outline: none;
  background-color: rgb(31, 31, 31);
  border: none;
  margin-right: 24px;
  width: 120px;
  height: 18px;
  padding: 4px 8px;
  font-size: 12px;
  color: #999;
}

.try-routing-popup-info {
  display: flex;
  position: absolute;
  top: 80px;
  border-radius: 2px;
  padding: 4px 8px;
  left: calc(50% - 265px);
  background-color: #272828;
  z-index: 8;
  width: 530px;
  height: 28px;
  border-radius: 2px;
  align-items: center;
  /* display: flex; */
  font-weight: 300;
  font-size: 12px;
  color: #999;
}

.route-status {
  position: relative;
  top: 0;
  margin-left: 24px;
  font-size: 12px;
  color: #999;
}

.route-status input {
  position: relative;
  top: 1px;
  outline: none;
  border: #999;

}